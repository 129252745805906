<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="height"
    :height="width"
    viewBox="0 0 19.653 14.039"
  >
    <path
      :style="iconStyle"
      d="M303.514,408.269l-2.434-2.132-6.951,5.956a1.389,1.389,0,0,0,.967.384h16.83a1.388,1.388,0,0,0,.965-.384l-6.951-5.956Zm0,0"
      transform="translate(-293.683 -398.438)"
    />
    <path
      :style="iconStyle"
      d="M312.891,402.134a1.376,1.376,0,0,0-.965-.384H295.1a1.4,1.4,0,0,0-.967.384l9.385,8.041Zm0,0"
      transform="translate(-293.683 -401.75)"
    />
    <path
      :style="iconStyle"
      d="M293.875,402.453v11.661l6.786-5.767Zm0,0"
      transform="translate(-293.875 -401.219)"
    />
    <path
      :style="iconStyle"
      d="M301.207,408.351l6.786,5.767V402.449Zm0,0"
      transform="translate(-288.34 -401.222)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '14.039',
    },
    width: {
      type: String,
      default: '19.653',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
